<template>
  <div class="catalog-container">
    <CatalogBanner/>
    <div class="row mx-0 mt-2">
      <div class="catalog-breadcrumb col mb-2">
        <router-link :to="`/product-catalog/${$route.params.slug}`">Home</router-link>
      </div>
      <!-- <div class="col-lg-2 col-6 mb-2 align-self-center">
        <b-button variant="success" class="btn-share-catalog float-right" v-b-modal.modal-share-link>
          Share <div class="svg-icon svg-share-icon ml-2"></div>
        </b-button>
      </div> -->
      <div class="group-catalog-list col-12">
        <!-- <router-link class="group-catalog-item" v-for="group in catalogGroups" :key="group.id" :to="encodeURL(group)"> -->
        <router-link class="group-catalog-item" v-for="group in catalogGroups" :key="group.id" :to="`${$route.params.slug}/${$helpers.format.slug(`${group.name} ${group.id}`)}`">
          {{ group.name }}
        </router-link>
      </div>
    </div>
    <!-- <ModalShareLink title="Share Product Catalog" :link="shareableLink" /> -->
    <CatalogFooter />
  </div>
</template>
<script>
const CatalogBanner = () => import("@/components/resellers/catalog/CatalogBanner");
const CatalogFooter = () => import("@/components/resellers/catalog/CatalogFooter");
// const ModalShareLink = () => import("@/components/modals/ModalShareLink");

export default {
  name: "GroupCatalogForCustomer",
  components: {
    CatalogBanner,
    CatalogFooter,
    // ModalShareLink,
  },
  data() {
    return {
      catalogGroups: [],
      // shareableLink: process.env.VUE_APP_FE_URL + this.$router.currentRoute.path.substring(1)
    }
  },
  mounted() {
    this.getCatalogGroupList();
  },
  methods: {
    async getCatalogGroupList() {
      try {
        const id = this.$route.params.slug.split("-").pop()
        const res = await this.$api.catalog.getCatalogGroupList(id);
        if (res.status === 200) this.catalogGroups = res.data.data
      } catch (error) {
        console.error(error)
      }
    },
    encodeURL(group) {
      return `${this.$route.params.slug}/${encodeURIComponent(group.name).replace(/%20/g,'+')}-${group.id}`
    },
  },
}
</script>